import palette from './palette';
import backgrounds from './backgrounds';
import header from './header';
import footer from './footer';
import search from './search';
import contacts from './contacts';
import email from './email';
import analytics from './analytics';
import internals from './internals';
import corporate from './corporate';
import togglers from './togglers';
import strings from './strings';
import system from './system';
import loyalty from './loyalty';
import hertz from './hertz';
import esim from './esim';

/**
 * It is a `configurable.json` as a commonjs module.
 */
export default {
  version: '1',
  fields: [
    // Customizable sections
    ...palette,
    ...backgrounds,
    ...header,
    ...footer,
    ...search,
    ...contacts,
    ...email,
    ...loyalty,
    ...system,
    ...analytics,
    ...hertz,
    ...esim,

    // Internal sections
    ...internals,
    ...corporate,
    ...togglers,
    ...strings,
  ],
};
