const ESIM = 'esim';

export default [
  {
    name: 'ESIM_ENABLE',
    type: 'bool',
    default: false,
    description: 'Enable eSIM',
    category: ESIM,
  },
  {
    name: 'ESIM_COVER_IMAGE',
    type: 'image',
    default: '',
    description: 'Custom form cover image',
    category: ESIM,
  },
  {
    name: 'ESIM_SUPPORT_EMAIL',
    type: 'string',
    default: 'travel-extras@mozio.com',
    description: 'Support email',
    category: ESIM,
  },
  {
    name: 'ESIM_SUPPORT_PHONE_NUMBERS',
    type: 'pairs',
    default: [['default', '+1 (855) 980-7116']],
    description:
      'Support phone number by active language. ' +
      '<key: default> – showed when sopport phone not exists; ' +
      '<key: en_US> – only US phone number',
    category: ESIM,
  },
];
