import { isNull, isInvalid } from './types';
import {
  REQUIRED_ERROR_TYPE,
  INVALID_ERROR_TYPE,
  INVALID_EMAIL_ERROR_TYPE,
  INVALID_PHONE_NUMBER_ERROR_TYPE,
} from 'app/constants';
import validationMessageStyle from 'app/components/base/ValidationMessage/style.css';

const APPROXIMATE_FIELD_HEIGHT = 100;

const switchTypeError = (value, type) => {
  switch (type) {
    case 'email':
      return INVALID_EMAIL_ERROR_TYPE;
    case 'phone':
      return INVALID_PHONE_NUMBER_ERROR_TYPE;
    case 'creditCardNumber':
      return value.error.message;
    case 'creditCardExpiry':
      return value.error.message;
    case 'creditCardCVC':
      return value.error.message;
    default:
      return INVALID_ERROR_TYPE;
  }
};

export const defineTypes = (fields) => (values) => {
  const errors = {};
  Object.keys(fields).forEach((key) => {
    const { isRequired, requiredError, type = 'text', validate } = fields[key];
    const path = key.split('.');
    const value = path.reduce((acc = {}, partKey) => acc[partKey], values);

    if (validate) {
      const validError = validate(value);
      if (validError) {
        Object.assign(errors, { [path]: validError });
      }
    } else if (isRequired && isNull(value, type)) {
      Object.assign(errors, { [path]: requiredError || REQUIRED_ERROR_TYPE });
    } else if (!isNull(value, type) && isInvalid(value, type)) {
      Object.assign(errors, { [path]: switchTypeError(value, type) });
    }
  });
  return errors;
};

export const scrollToFirstInvalidField = () => {
  const itemNode = document.querySelector(
    `.${validationMessageStyle.validationMessage}`
  );
  const newItemNode = document.querySelector(
    '.moz-base-field__errors:not(:empty)'
  );
  const actualNode = itemNode || newItemNode;
  if (!actualNode) {
    return;
  }

  const boundaries = actualNode.getClientRects()[0];
  document.body.scrollTop += boundaries.top - APPROXIMATE_FIELD_HEIGHT;
};

export const shouldShowErrors = (
  { active, touched, dirty, visited, error, warning },
  useDirty
) => {
  return Boolean(
    !active && (touched || visited || (useDirty && dirty)) && (error || warning)
  );
};
