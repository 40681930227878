export default {
  ENV: 'prod',
  USER_NAVIGATION_SHOW: true,
  FOOTER_SHOW: true,
  TRAVELER_OPTIONS_SHOW: true,
  POPULAR_RIDE_SHOW: true,
  SHOW_SIGNIN_BUTTON: true,
  SHOW_SRP_MAP: true,
  REGISTER_TA_SHOW: true,
  GOOGLE_ANALYTICS_KEY_PARTNER: 'UA-86969688-1',
  SHOW_SOCIAL_LOGIN: true,
  SHOW_PARKCLOUD_BANNER: true,
  SHOW_MOZIO_GROUP_HOMEPAGE: true,
  SHOW_PARKING_SEARCH: true,
  VEHICLE_CLASS_TOP_RESULTS: true,
  // MINIMAL_SUPPLIER_SCORE: 8,
};
