import {
  SEARCH_PARAM_DATE_FORMAT,
  countryCodeToRawSuggestion,
} from 'app/utils/types';
import countries from 'mz-ui-kit/form/CountryField/countries';
import { hook } from 'app/utils/hook';
import languages from 'app/languages';
import { getLocaleFreePathname } from 'app/history';
import { getMainStep } from 'app/utils/trip';
import { getFormValues, formValueSelector } from 'redux-form';
import { getCustomStripeConfig } from 'app/utils/stripe';
import { getConfigByProps } from 'mz-ui-kit/form/PaymentButton';
import { flightToPickupTimeFromAirport } from 'app/utils/flight';
import config from 'config';
import {
  SEARCH_RESULTS_PAGE_PATH,
  TRAVEL_AGENT_MASTER_ACCOUNT,
  HOURLY_MODE_VALUE,
  DEPARTING_TRIP_FIELD_NAME,
  RETURN_TRIP_FIELD_NAME,
  COUPON_CODE_FIELD_NAME,
  COUPON_STATUS,
  UPGRADES_FIELD_NAME,
  BOOKING_FEE_STATUS,
  BOOKING_FEE_FIELD_NAME,
  GRATUITY_FIELD_NAME,
  GRATUITY_STATUS,
  SESSION_ID_FIELD_NAME,
  PRICE_FIELD_NAME,
  BOOK_TRIP_FORM,
  ON_DEMAND_CATEGORY,

  // used for scheduled ride price calculation
  SELECTED_TICKET_FIELD_NAME,
  SELECTED_RETURN_TICKET_FIELD_NAME,
  SELECTED_CREDIT_CARD_ID,
  FIRST_NAME_FIELD_NAME,
  LAST_NAME_FIELD_NAME,
  EMAIL_FIELD_NAME,
  EMAIL_CONFIRMATION_FIELD_NAME,
  PHONE_CODE_FIELD_NAME,
  PHONE_NUMBER_FIELD_NAME,
  TA_CONFIRMATION_EMAIL_FIELD_NAME,
  SELECTED_CUSTOMER_PROFILE,
  CC_ADD_NEW_OPENED,
  USER_TYPE_TA,
  TA_CUSTOMER_PROFILE_FORM,
  TA_AGENT_ID_FIELD_NAME,
  TA_IATA_FIELD_NAME,
  ACCOUNT_ID_FIELD_NAME,
  ACCOUNT_ID_STATUS,
  CUSTOMER_FEEDBACK_FORM,
  ADDITION_SEARCH_ARGUMENTS_HOOK,
  PLACE_SUGGESTIONS_PARAMS_HOOK,
  OVERRIDE_USER_FIELDS_HOOK,
  USER_TYPE_TC,
} from 'app/constants';
import { createSelector } from 'reselect';
import queryStringToObject from 'mz-utils/queryStringToObject';
import srpFormConfig from 'app/forms/search-form';
import { formConfig as btpFormConfig } from 'app/pages/BookTripPage/builder';
import _ from 'lodash';

export const getUser = (state) => state.userSession.user;
export const getIntlMessages = (state) => state.app.messages;
export const getActiveCurrency = (state) => state.app.currency;
export const getUserCreditCards = (state) => state.creditcards.list;
export const getRedirectBackUrl = (state) => state.userSession.redirectBackUrl;
export const getAutocomplete = (state, id) => state.autocomplete[id] || {};

export const getActiveLanguage = (state) => state.app.locale;
export const getActiveLanguageObject = (state) => {
  const locale = getActiveLanguage(state);
  return languages[locale] || languages.en_US;
};

export const getSearchForm = getFormValues(srpFormConfig.form);
export const getCurrency = (state) => state.app.currency;
export const getSearchId = (state) => state.searchResults.search_id;
export const getActiveSearchFilters = (state) =>
  state.searchResults.activeFilters;
export const getSearchLoading = (state) => state.searchResults.searching;
export const getSearchExpirationTime = (state) =>
  state.searchResults.search_expiration;
export const getSavedSearch = (state) => state.searchResults.search;
export const getSearchResultsTrips = (state) => state.searchResults.trips;

export const getLocation = createSelector(
  (state) => state.router.location || window.location,
  (location) => ({
    ...location,
    query: queryStringToObject(location.search),
  })
);

export const isUserDataReady = (state) => !!state.userSession.ready;
export const getLoggedIn = (state) => !!state.userSession.user;
export const getCustomerProfile = (state, customer_id) => {
  if (!customer_id) return state.travelAgent.profile;
  return state.travelAgent.profileList.find((customer) => {
    return String(customer.id) === String(customer_id);
  });
};
export const isProfileListLoading = (state) =>
  state.travelAgent.isProfileListLoading;

export const getBookingAttempts = (state) =>
  state.bookTrip.bookingAttempts ?? 0;
export const getBookTripParams = ({
  bookTrip: {
    booking: { session_id, trip_id },
  },
}) => ({
  session_id,
  trip_id,
});
export const getPaymentOption = (state, id) =>
  state.creditcards &&
  state.creditcards.list.find((x) => `${x.id}` === `${id}`);
export const getSavedBooking = ({ bookTrip: { booking } }) => booking;
export const isHourlyRideSearch = (state) => {
  const location = getLocation(state);

  const { mode } = location.query || {};
  const isHourly = mode === HOURLY_MODE_VALUE;

  return isHourly;
};
export const getRecommendedTripIds = (state) => {
  const {
    searchResults: { recommendedTripIds },
  } = state;
  return recommendedTripIds || [];
};
export const getSearchArguments = (state) => {
  const form = getSearchForm(state);
  if (!form) return null;

  const {
    start_address = {},
    end_address = {},
    pickup_datetime,
    return_pickup_datetime,
    mode,
    booking_mode,
    hourly_booking_duration,
    num_passengers,
    flight,
    return_flight,
    [SELECTED_CUSTOMER_PROFILE]: customer_profile,
  } = form;

  // pickup_date and return_pickup_date converted to moment object because MobileDatetimePicker
  // return Date obj
  const values = {
    start_address,
    end_address,
    pickup_datetime: flightToPickupTimeFromAirport(flight, pickup_datetime)
      .locale('en')
      .format(SEARCH_PARAM_DATE_FORMAT),
    mode,
    booking_mode,
    hourly_booking_duration,
    num_passengers,
    currency: getCurrency(state),
    ...(flight && {
      airline: flight.airlineCode,
      flight_number: flight.flightNumber,
    }),
    ...(return_flight && {
      airline2: return_flight.airlineCode,
      flight_number2: return_flight.flightNumber,
    }),
  };

  if (customer_profile) values.customer_id = customer_profile.id;

  const isHourlyRide = booking_mode === HOURLY_MODE_VALUE;

  if (!isHourlyRide && return_pickup_datetime) {
    values.return_pickup_datetime = flightToPickupTimeFromAirport(
      return_flight,
      return_pickup_datetime
    )
      .locale('en')
      .format(SEARCH_PARAM_DATE_FORMAT);
  }

  _.assign(values, hook(ADDITION_SEARCH_ARGUMENTS_HOOK, state, values));

  return values;
};
export const getPlaceSuggestionsParams = (state) => {
  const params = hook(PLACE_SUGGESTIONS_PARAMS_HOOK, state);
  return params;
};
export const getBookTripErrorCode = (state) => state.bookTrip.errorCode;
export const getBookTripForm = getFormValues(BOOK_TRIP_FORM);
export const getSearchFormFieldError = (state, field) => {
  if (!state.form[srpFormConfig.form].submitFailed) return null;
  const { syncErrors = {} } = state.form[srpFormConfig.form];
  return syncErrors[field];
};
export const getBookTripFormErrors = (state) =>
  state.form[BOOK_TRIP_FORM].syncErrors;
export const getTripToBook = (state, tripId) => {
  return tripId ? state.searchResults.trips[tripId] : null;
};
export const getTripPrice = (state, formName = BOOK_TRIP_FORM) =>
  getFormValues(formName)(state)[PRICE_FIELD_NAME];
export const getCouponFieldValue = (state) =>
  getBookTripForm(state)[COUPON_CODE_FIELD_NAME];
export const getCouponStatus = (state) => getBookTripForm(state)[COUPON_STATUS];
export const getBookingFeeFieldValue = (state) =>
  getBookTripForm(state)[BOOKING_FEE_FIELD_NAME];
export const getBookingFeeStatus = (state) =>
  getBookTripForm(state)[BOOKING_FEE_STATUS];
export const getGratuityFieldValue = (state) =>
  getBookTripForm(state)[GRATUITY_FIELD_NAME];
export const getGratuityStatus = (state) =>
  getBookTripForm(state)[GRATUITY_STATUS];
export const getBookingEmail = (state) =>
  getBookTripForm(state)[EMAIL_FIELD_NAME];
export const getAccountIdFieldValue = (state) =>
  getBookTripForm(state)[ACCOUNT_ID_FIELD_NAME];
export const getAccountIdStatus = (state) =>
  getBookTripForm(state)[ACCOUNT_ID_STATUS];
export const getTripUpgrades = (state) =>
  getBookTripForm(state)[UPGRADES_FIELD_NAME];
export const getSelectedCustomerProfile = (state) =>
  getBookTripFormValue(state, SELECTED_CUSTOMER_PROFILE);
export const getBookingTrip = (state) =>
  getBookTripForm(state)[DEPARTING_TRIP_FIELD_NAME];
export const getProviderDisclaimer = (state) => {
  const price = getTripPrice(state) || {};
  const result = {};

  // Direct
  const trip = getBookTripForm(state)[DEPARTING_TRIP_FIELD_NAME];
  const step = getMainStep(trip);
  if (trip && step) {
    result.disclaimer = price.disclaimer;
    result.details = step.bookingDetails.notes;
  }

  // Return
  const returnTrip = getBookTripForm(state)[RETURN_TRIP_FIELD_NAME];
  const returnStep = getMainStep(trip);
  if (returnTrip && returnStep) {
    result.returnDisclaimer = price.returnDisclaimer;
    result.returnDetails = returnStep.bookingDetails.notes;
  }

  return result;
};
export const isBookingEmailTaken = (state) => state.bookTrip.emailTaken;
export const getFieldsFromCustomer = (state) => {
  const { customer_id } = getLocation(state).query;
  const customer = customer_id
    ? getCustomerProfile(state, customer_id)
    : _.get(getBookTripForm(state), SELECTED_CUSTOMER_PROFILE);
  if (!customer) return {};
  const fields = {
    [SELECTED_CUSTOMER_PROFILE]: customer,
    [FIRST_NAME_FIELD_NAME]: customer.first_name,
    [LAST_NAME_FIELD_NAME]: customer.last_name,
    [EMAIL_FIELD_NAME]: customer.email,
    [EMAIL_CONFIRMATION_FIELD_NAME]: customer.email,
    [PHONE_CODE_FIELD_NAME]: countryCodeToRawSuggestion(
      customer.country_code_name
    ),
    [PHONE_NUMBER_FIELD_NAME]: customer.phone_number_national,
  };

  const card = customer.payment_option;

  if (card && card.id) {
    Object.assign(fields, {
      [SELECTED_CREDIT_CARD_ID]: String(card.id),
      [CC_ADD_NEW_OPENED]: false,
    });
  } else {
    Object.assign(fields, {
      [CC_ADD_NEW_OPENED]: true,
    });
  }

  return _.omitBy(fields, _.isNil);
};

export const getBookingCancellationNotice = (state) => {
  const trip = getBookingTrip(state);
  if (!trip) return null;
  const {
    bookingDetails: { cancellationPolicy },
  } = getMainStep(trip);
  return cancellationPolicy?.[0]?.notice ?? null;
};

export const getFieldsFromUser = (state) => {
  const user = getUser(state);
  if (!user) return {};

  // Try to get fields from hook firstly
  const fieldsFromHook = hook(OVERRIDE_USER_FIELDS_HOOK, user, state);
  if (fieldsFromHook) {
    return fieldsFromHook;
  }

  // We only prefill user form for normal users.
  // For TAs, we only prefill the confirmation email for now.
  const isTravelAgent = user.user_type === USER_TYPE_TA;

  if (isTravelAgent) {
    return _.omitBy(
      {
        [TA_AGENT_ID_FIELD_NAME]: user.external_agent_id,
        [TA_IATA_FIELD_NAME]: user.iata_code,
        [PHONE_CODE_FIELD_NAME]: countryCodeToRawSuggestion(
          user.country_code_name,
          true
        ),
        [BOOKING_FEE_FIELD_NAME]:
          user.commission_percentage > 0 ? '' : user.commission,
        [TA_CONFIRMATION_EMAIL_FIELD_NAME]: user.email,
      },
      _.isNil
    );
  }

  return _.omitBy(
    {
      [FIRST_NAME_FIELD_NAME]: user.first_name,
      [LAST_NAME_FIELD_NAME]: user.last_name,
      [EMAIL_FIELD_NAME]: user.email,
      [EMAIL_CONFIRMATION_FIELD_NAME]: user.email,
      [PHONE_CODE_FIELD_NAME]: countryCodeToRawSuggestion(
        user.country_code_name,
        true
      ),
      [PHONE_NUMBER_FIELD_NAME]: user.phone_number_national,
    },
    _.isNil
  );
};

export const getAddedUpgrades = (upgrades) => {
  const addedUpgrades = upgrades
    .filter((upgrade) => upgrade.included && upgrade.optional)
    .map((upgrade) => upgrade.id);

  return addedUpgrades;
};
export const getBookTripFormValue = (state, fieldName) => {
  const bookTripForm = getBookTripForm(state);
  return bookTripForm[fieldName];
};

export const getBookingFee = (state) => {
  const user = getUser(state);
  const percentage = user && user.commission_percentage > 0;
  const rawValue =
    (user && (user.commission_percentage || user.commission)) || 0;
  const value = parseFloat(rawValue).toFixed(2);

  return {
    value,
    percentage,
    currency: (user && user.currency) || 'USD',
    fixed: !percentage,
  };
};

/**
 * Returns selected departure ticket
 */
export const getDepartureTicket = (state, formName = BOOK_TRIP_FORM) => {
  const departureTicket =
    getFormValues(formName)(state)[SELECTED_TICKET_FIELD_NAME];

  return departureTicket;
};

/**
 * Returns selected return ticket
 */
export const getReturnTicket = (state, formName = BOOK_TRIP_FORM) => {
  const returnTicket =
    getFormValues(formName)(state)[SELECTED_RETURN_TICKET_FIELD_NAME];

  return returnTicket;
};

function normalizeCardBrand(cardBrand) {
  return cardBrand === 'Mastercard' ? 'MasterCard' : cardBrand;
}

/**
 * Return all price properties needed for the getPrice SDK method
 */
export const getPriceComponents = (state, formName = BOOK_TRIP_FORM) => {
  // Only trip Id and session Id is difference wen get pricing details for
  // updated trip from "Trip Updated" dialog
  const trip = getFormValues(formName)(state)[DEPARTING_TRIP_FIELD_NAME] || {};
  const sessionId = getFormValues(formName)(state)[SESSION_ID_FIELD_NAME];
  const upgrades = getTripUpgrades(state);
  const user = getUser(state);
  const shouldUseMasterCommission = !!user?.company?.use_master_commission;

  const departureTicket = getDepartureTicket(state, formName);
  const returnTicket = getReturnTicket(state, formName);

  const scheduledRideParams = _.omitBy(
    {
      ticket_id: departureTicket && departureTicket.ticketId,
      alternative_time_index: departureTicket && departureTicket.scheduleId,
      return_ticket_id: returnTicket && returnTicket.ticketId,
      return_alternative_time_index: returnTicket && returnTicket.scheduleId,
    },
    _.isNil
  );

  const ccParams = state.bookTrip.cardBrand
    ? { cc_type: normalizeCardBrand(state.bookTrip.cardBrand) }
    : null;

  const selectedBookingFee =
    getBookTripFormValue(state, BOOKING_FEE_STATUS).savedValue ?? '';
  const booking_fee =
    !shouldUseMasterCommission && selectedBookingFee !== ''
      ? { booking_fee: selectedBookingFee }
      : {};

  return {
    trip_id: trip.id || null,
    session_id: sessionId,
    currency: getCurrency(state),
    optional_amenities: getAddedUpgrades(upgrades),
    coupon_code: getBookTripFormValue(state, COUPON_STATUS).savedValue,
    gratuity: getBookTripFormValue(state, GRATUITY_STATUS).savedValue || 0,
    ta_email:
      getBookTripFormValue(state, ACCOUNT_ID_STATUS).savedValue || undefined,
    ondemand: trip.type === ON_DEMAND_CATEGORY,
    ...booking_fee,
    ...ccParams,
    ...scheduledRideParams,
  };
};

export const getReservation = (state) =>
  state.confirmation.departing || state.confirmation.returning || {};
export const getDepartureReservation = (state) =>
  (state.confirmation && state.confirmation.departing) || {};
export const getReturnReservation = (state) =>
  (state.confirmation && state.confirmation.returning) || {};
export const getConfirmationNumber = (state) =>
  getReservation(state).confirmationNumber || '...';
export const getPickupInstructions = (reservation) =>
  reservation.pickupInstructions;
export const getSpecialRequests = (state) =>
  getReservation(state).trip.specialInstructions;
export const getExtraTripOffer = (state) =>
  getReservation(state)?.extraTripOffer;

export const getTASelectedCustomerProfileId = ({
  travelAgent: { profileId },
}) => profileId;
export const getTACustomerProfileForm = getFormValues(TA_CUSTOMER_PROFILE_FORM);

// omits keys that arent needed for debugging
export const getDebugState = ({ app, ...state }) => ({
  app: _.omit(app, 'messages'),
  ...state,
});

// return initialized state of BookTripPage
export const isSearchResultsPage = createSelector(getLocation, (location) =>
  getLocaleFreePathname(location.pathname).startsWith(SEARCH_RESULTS_PAGE_PATH)
);
export const isBookTripPage = (state) =>
  state.form[btpFormConfig.form] &&
  state.form[btpFormConfig.form].registeredFields &&
  getLocaleFreePathname(getLocation(state).pathname).startsWith('/book');

export const isLoading = (state) => state.app.loading;

export const isTravelAgent = ({ userSession: { user } }) =>
  user && user.user_type === USER_TYPE_TA;
export const isTC = ({ userSession: { user } }) =>
  user && user.user_type === USER_TYPE_TC;
export const behavesAsTravelAgent = (state) =>
  isTravelAgent(state) || isTC(state);

export const isMasterTravelAgent = (state) =>
  isTravelAgent(state) &&
  state.userSession.user.position === TRAVEL_AGENT_MASTER_ACCOUNT;
export const isTravelAgentCredits = ({ userSession: { user } }) =>
  user && user.travel_agents_credits;
export const shouldTAAccessWithdrawalsPages = (state) =>
  config.EDITABLE_TA_WITHDRAWAL &&
  (isMasterTravelAgent(state) ||
    !state.userSession.user.company ||
    !state.userSession.user.company.payout_to_master);

export const shouldTAAccessAccountTab = (state) => {
  return (
    state.userSession.user?.company &&
    (isMasterTravelAgent(state) ||
      !state.userSession.user.company.payout_to_master ||
      !state.userSession.user.company.use_master_commission ||
      config.AGENT_IATA_AND_ID_ENABLED)
  );
};

export const isBookByCustomer = (state) => {
  return isTravelAgent(state) && !!state.userSession.user?.book_by_customer;
};

export const getChangedModeTrip = (state) => state.bookTrip.changed_mode_trip;
export const getShowChargeInUsdMessage = (state) =>
  state.bookTrip.showChargeInUsdMessage;

export const getSupportPhones = (state) => {
  const lang = getActiveLanguage(state);

  // Split phones list by key
  const phones = config.SUPPORT_PHONE_NUMBERS.reduce(
    (acc, v) => {
      if (v[0] === 'any') {
        acc.any.push(v[1]);
      } else if (v[0] === 'default') {
        acc.default.push(v[1]);
      } else if (v[0] === lang) {
        acc.lang.push(v[1]);
      }
      return acc;
    },
    { any: [], default: [], lang: [] }
  );

  // Get retuls phone numbers
  const resultPhones = [
    ...phones.any,
    ...(phones.lang.length ? phones.lang : phones.default),
  ];

  return resultPhones;
};

export const getCustomerFeedbackForm = getFormValues(CUSTOMER_FEEDBACK_FORM);

// scheduled rides
export const getUpdatedScheduleTrip = (state) => state.scheduledRides.trip;
export const getUpdatedScheduleError = (state) => state.scheduledRides.error;
export const getUpdatedScheduleLoading = (state) =>
  state.scheduledRides.loading;

// Pricing selectors
export const isForceSecurePayment = (state) => {
  const form = getBookTripForm(state);
  const priceField = form[PRICE_FIELD_NAME];
  return !!(priceField && priceField.forceThreeDSecure);
};
export const isPriceUpdating = (state) => {
  const form = getBookTripForm(state);
  const priceField = form[PRICE_FIELD_NAME];
  return (
    !priceField ||
    priceField.updating === true ||
    priceField.updating === undefined
  );
};
export const getStripePricing = (state) => {
  const form = getBookTripForm(state);
  const forceThreeDSecure = isForceSecurePayment(state);
  const {
    currency: displayCurrency,
    finalChargeStripePrice: amount,
    finalChargeCurrency: currency,
    finalChargePrice: finalPrice,
    supports,
  } = form[PRICE_FIELD_NAME] || {};

  return {
    displayCurrency,
    currency,
    amount,
    finalPrice,
    forceThreeDSecure,
    supports: supports || {},
  };
};
export const getDisplayPrice = (state) => {
  const pricing = getTripPrice(state);
  return pricing && pricing.finalPrice ? pricing.finalPrice : '...';
};

export const isFullDiscountActive = (state) => {
  const { finalPriceUsd, finalPriceRaw } = getTripPrice(state);

  const { savedValue: couponCode } = getCouponStatus(state);

  return finalPriceUsd === 0 && finalPriceRaw === 0 && !_.isEmpty(couponCode);
};

// SRP map
export const isSRPMapShowed = (state) => state.srpMap.showMap;
export const getCachedDirection = (key) => (state) => {
  return state.srpMap.cachedDirections[key];
};
export const getActiveTripIdForSrpMap = (state) => state.srpMap.tripId;

export const getPhoneCodePrefix = (state, form, name) => {
  const phoneCode = _.get(getFormValues(form)(state), `${name}.code`);
  if (!phoneCode) return null;
  const { callingCode } = countries[phoneCode];
  return `(${callingCode})`;
};

export const getStripeOrigin = (state) => {
  const stripeConfig = getConfigByProps({
    stripeConfigs: getCustomStripeConfig(),
    currency: getCurrency(state).toUpperCase(),
    devMode: config.STRIPE_DEVELOPMENT_MODE,
  });
  return stripeConfig?.origin || 'USD';
};

// Meet and Greet suggestions
export const getMeetAndGreetTrips = (state) => state.meetAndGreetTrips.trips;

export const getTACustomerProfileCCList = (state) => {
  const { creditcards } = state;

  let cardsList = creditcards ? creditcards.list : [];

  const selector = formValueSelector(BOOK_TRIP_FORM);
  const selectedCustomerProfile = selector(state, SELECTED_CUSTOMER_PROFILE);

  if (behavesAsTravelAgent(state)) {
    cardsList =
      !_.isEmpty(selectedCustomerProfile) &&
      selectedCustomerProfile.payment_option
        ? cardsList.filter(
            (card) => card.id === selectedCustomerProfile.payment_option.id
          )
        : [];
  }

  return cardsList;
};

export const isAirportReservation = (state) => {
  const { trip } = getReservation(state);
  const step = getMainStep(trip);
  if (!step) return false;
  return (
    !!(step.from && step.from.location.iataCode) ||
    !!(step.to && step.to.location && step.to.location.iataCode)
  );
};

// Favourite locations
export const getFavouriteLocations = (state) => state.favouriteLocations.places;

export const getCustomersPaymentOptionId = (state) => {
  const form = getBookTripForm(state);
  const optionId = form && form[SELECTED_CREDIT_CARD_ID];
  return optionId ? parseInt(optionId, 10) : null;
};

export const getReservationFilters = (state) => state.reservations.filters;
export const getActiveReservationsTab = (state) => state.reservations.activeTab;

// eSIM
export const getEsimUrl = (state) => {
  const url = new URL(window.location.href);
  const esimUrl = `${url.origin}/esim?locale=${getActiveLanguage(
    state
  )}&currency=${getActiveCurrency(state)}`;
  return esimUrl;
};
