import React, { Component } from 'react';
import { FormattedMessage } from 'translations';

import { Layout, Flex } from 'app/components/base/Layout';
import Tabs from 'app/components/base/Tabs';
import Pane from 'app/components/base/Tabs/Pane';
import LoadingSpinner from 'app/components/base/LoadingSpinner';
import HeaderBar from './HeaderBar';
import TableItem from './TableItem';
import Title from './Title';
import Button from 'app/components/base/Button';
import { DAY_FORMAT } from 'app/utils/types';
import transformTicketTypesToMatrix from './utils/transformTicketTypesToMatrix';
import UpArrow from './assets/schedule-earlier-times-button.svg?url';
import DownArrow from './assets/schedule-later-times-button.svg?url';

import style from './style.css';

const messages = {
  priceFrom: { id: 'TRIP_DETAILS.PRICE_FROM' },
  select: { id: 'TRIP_DETAILS.SELECT' },
  departure: { id: 'TRIP_DETAILS.DEPARTURE' },
  return: { id: 'TRIP_DETAILS.RETURN' },
  earlierTimes: { id: 'TRIP_DETAILS.EARLIER_TIMES' },
  laterTimes: { id: 'TRIP_DETAILS.LATER_TIMES' },
  scheduleResearchError: { id: 'BOOK_TRIP.SCHEDULE_RESEARCH_ERROR' },
};

/**
 * Component for selecting departure and return schedules
 */
class MobileTimeTableSelect extends Component {
  state = {
    selectedDepartureTicket: null,
    selectedReturnTicket: null,
    activeTabIndex: 0,
  };

  componentWillMount() {
    const { departureScheduleProps, returnScheduleProps } = this.props;
    const { selectedTicket: selectedDepartureTicket } = departureScheduleProps;

    let initialState = {
      selectedDepartureTicket,
      departureTicketType: selectedDepartureTicket.ticketId,
    };

    if (returnScheduleProps) {
      const { selectedTicket: selectedReturnTicket } = returnScheduleProps;

      initialState = {
        ...initialState,
        selectedReturnTicket,
        returnTicketType: selectedReturnTicket.ticketId,
      };
    }

    this.setState(initialState);
  }

  get isReturnTabOpened() {
    return this.state.activeTabIndex === 1;
  }

  handleActive = (item, isReturn) => {
    const selectedTicketName = isReturn
      ? 'selectedReturnTicket'
      : 'selectedDepartureTicket';

    this.setState({ [selectedTicketName]: item });
  };

  handleSelect = () => {
    const { selectedDepartureTicket, selectedReturnTicket } = this.state;

    // show return schedule after selecting departure
    if (this.props.returnScheduleProps && this.state.activeTabIndex === 0) {
      this.setState({ activeTabIndex: 1 });
      return;
    }

    if (!selectedDepartureTicket && !selectedReturnTicket) return;
    this.props.onConfirm({
      departureTicket: selectedDepartureTicket,
      returnTicket: selectedReturnTicket,
      updatedTrip: this.props.updatedTrip,
    });
  };

  lowestPrice(ticketType) {
    return ticketType.schedules.reduce(
      (current, next) => {
        return next.price.value < current.value ? next.price : current;
      },
      { value: Infinity }
    );
  }

  renderItem = (option, ticketTypes, isReturn) => {
    return (
      <TableItem
        value={option}
        compact
        onSelect={this.handleActive}
        key={option.hash}
        className={style.table_item}
        ticketTypes={ticketTypes}
        isReturn={isReturn}
        selectedTicket={
          isReturn
            ? this.state.selectedReturnTicket
            : this.state.selectedDepartureTicket
        }
      />
    );
  };

  renderSchedule = (scheduleProps, isReturn) => {
    const { loading, error } = this.props;
    const { serviceHoursInfo, fromInfo, dateInfo, title, titleLogo } =
      scheduleProps;

    const ticketTypeStatePropName = isReturn
      ? 'returnTicketType'
      : 'departureTicketType';

    const ticketTypes = scheduleProps.ticketTypes.filter(
      (t) => t.ticketId === this.state[ticketTypeStatePropName]
    );

    const schedulesMatrix = transformTicketTypesToMatrix(ticketTypes);

    const tripInfoComponent = (
      <HeaderBar
        serviceHours={serviceHoursInfo}
        startLocation={fromInfo}
        date={dateInfo.format(DAY_FORMAT)}
      />
    );

    return (
      <div>
        <Title
          noclose
          logoSrc={titleLogo}
          title={title}
          tripInfo={tripInfoComponent}
        />
        <Layout align="space-around center" nowrap>
          {scheduleProps.ticketTypes.map((ticketType) => (
            <Button
              key={ticketType.ticketId}
              nocaps
              gray={this.state[ticketTypeStatePropName] !== ticketType.ticketId}
              disabled={loading}
              size="auto"
              large
              direction="column"
              onClick={() =>
                this.setState({
                  [ticketTypeStatePropName]: ticketType.ticketId,
                })
              }
            >
              <span>{ticketType.ticketName}</span>
              <span className={style['table__types__label--inactive']}>
                <FormattedMessage
                  {...messages.priceFrom}
                  values={{ price: this.lowestPrice(ticketType).display }}
                />
              </span>
            </Button>
          ))}
        </Layout>
        <Layout
          className={style.table}
          nowrap
          size="noshrink"
          direction="column"
          align="start stretch"
        >
          {error && (
            <Flex className={style.error} size="nogrow">
              <FormattedMessage {...messages.scheduleResearchError} />
            </Flex>
          )}
          {loading && (
            <Flex className={style.loading} size="nogrow">
              <LoadingSpinner size="large" />
            </Flex>
          )}
          {!loading &&
            schedulesMatrix.map((option) =>
              this.renderItem(option, ticketTypes, isReturn)
            )}
        </Layout>
      </div>
    );
  };

  renderSchedulesContent = () => {
    const { departureScheduleProps, returnScheduleProps } = this.props;

    if (!returnScheduleProps) {
      return this.renderSchedule(departureScheduleProps);
    }

    return (
      <Tabs
        active={this.state.activeTabIndex}
        onSelect={(index) => this.setState({ activeTabIndex: index })}
      >
        <Pane label={<FormattedMessage {...messages.departure} />} key="0">
          {this.renderSchedule(departureScheduleProps)}
        </Pane>
        <Pane label={<FormattedMessage {...messages.return} />} key="1">
          {this.renderSchedule(returnScheduleProps, true)}
        </Pane>
      </Tabs>
    );
  };

  render() {
    const { returnScheduleProps, viewEarlierTimes, viewLaterTimes, loading } =
      this.props;

    const selectionNotConfirmed = returnScheduleProps
      ? !this.state.selectedDepartureTicket || !this.state.selectedReturnTicket
      : !this.state.selectedDepartureTicket;

    return (
      <Layout
        className={style.container}
        direction="column"
        align="start stretch"
        size="noshrink"
        nowrap
      >
        {this.renderSchedulesContent()}
        <Layout
          className={style.select_button_container}
          direction="row"
          align="space-between center"
        >
          <Layout>
            <Button
              className={style.arrowButton}
              disabled={loading}
              transparent
              onClick={() => viewEarlierTimes(this.isReturnTabOpened)}
            >
              <UpArrow />
              <span className={style.arrowButton__text}>
                <FormattedMessage {...messages.earlierTimes} />
              </span>
            </Button>
            <Button
              className={style.arrowButton}
              disabled={loading}
              transparent
              onClick={() => viewLaterTimes(this.isReturnTabOpened)}
            >
              <DownArrow />
              <span className={style.arrowButton__text}>
                <FormattedMessage {...messages.laterTimes} />
              </span>
            </Button>
          </Layout>

          <Button
            nocaps
            rounded
            large
            onClick={this.handleSelect}
            disabled={selectionNotConfirmed || loading}
          >
            <FormattedMessage {...messages.select} />
          </Button>
        </Layout>
      </Layout>
    );
  }
}

// TODO: move to Flow types
// const ticketShape = {
//   ticketId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   scheduleId: PropTypes.number
// };
// const scheduleShape = {
//   ticketTypes: PropTypes.array,
//   serviceHoursInfo: PropTypes.string,
//   fromInfo: PropTypes.string,
//   dateInfo: PropTypes.object,
//   title: PropTypes.string,
//   titleLogo: PropTypes.string,
//   selectedTicket: PropTypes.shape(ticketShape)
// };
// MobileTimeTableSelect.propTypes = {
//   updatedTrip: PropTypes.object,
//   departureScheduleProps: PropTypes.shape(scheduleShape),
//   returnScheduleProps: PropTypes.shape(scheduleShape),
//   error: PropTypes.string,
//   loading: PropTypes.bool,
//   viewEarlierTimes: PropTypes.func,
//   viewLaterTimes: PropTypes.func,
//   onClose: PropTypes.func,
//   onConfirm: PropTypes.func.isRequired
// };

export default MobileTimeTableSelect;
