import React, { Component } from 'react';
import { confirmBooking, loadBookTrip } from 'app/actions/book-trip';
import { getCustomerProfileList } from 'app/actions/travel-agent';
import { createStripeValue } from 'app/utils/types';
import config from 'config';
import allowOrRedirect from '../allowOrRedirect';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { defineTypes, scrollToFirstInvalidField } from 'app/utils/forms';
import { preprocessTripObject } from 'app/utils/trip';
import {
  getUser,
  getTACustomerProfileCCList,
  behavesAsTravelAgent,
} from 'app/sagas/selectors';
import { connectSubpages } from 'app/hocs/subpageManagable';
import LoadingBox from 'app/components/book-trip/commons/LoadingBox';
import ErrorLayout from './commons/ErrorLayout';
import {
  NOT_SAME_EMAIL_ERROR_TYPE,
  BOOK_TRIP_FORM,
  RETURN_DATETIME_FIELD_NAME,
  COUPON_CODE_FIELD_NAME,
  DEPARTING_PICKUP_FIELD_NAME,
  RETURNING_PICKUP_FIELD_NAME,
  RIDE_DURATION_FIELD_NAME,
  COUPON_STATUS,
  FIRST_NAME_FIELD_NAME,
  LAST_NAME_FIELD_NAME,
  EMAIL_FIELD_NAME,
  EMAIL_CONFIRMATION_FIELD_NAME,
  PHONE_CODE_FIELD_NAME,
  PHONE_NUMBER_FIELD_NAME,
  TRAVELER_FIELD_NAME,
  TRAVELER_FIRST_NAME_FIELD_NAME,
  TRAVELER_LAST_NAME_FIELD_NAME,
  TRAVELER_EMAIL_FIELD_NAME,
  TA_CONFIRMATION_EMAIL_FIELD_NAME,
  UPGRADES_FIELD_NAME,
  PRICE_FIELD_NAME,
  BOOKING_FEE_FIELD_NAME,
  GRATUITY_FIELD_NAME,
  MEET_AND_GREET_BOX_CLOSED,
  DEPARTING_TRIP_FIELD_NAME,
  AGREE_WITH_TERMS_FIELD_NAME,
  BOOKING_FEE_STATUS,
  GRATUITY_STATUS,
  PASSWORD_FIELD_NAME,
  DEPARTING_FLIGHT_FIELD_NAME,
  RETURNING_FLIGHT_FIELD_NAME,
  RETURN_BOOK_STATE_FIELD_NAME,
  RETURN_TRIP_BOOKED,
  ACCOUNT_ID_FIELD_NAME,
  ACCOUNT_ID_STATUS,
  SAVE_TA_PROFILE_FIELD_NAME,
  SAVE_TA_CC_FIELD_NAME,
  TRAIN_INFO_FIELD_NAME,
  RETURN_TRAIN_INFO_FIELD_NAME,
  RETURN_TRIP_FIELD_NAME,
  MUST_AGREE_TNC_ERROR_TYPE,
  LOYALTY_MEMBER_NUMBER_FIELD_NAME,
  DEPARTING_CRUISE_FIELD_NAME,
  DEPARTING_SHIP_FIELD_NAME,
} from 'app/constants';

// Constants
export const initialValues = {
  cc_cvc: createStripeValue(),
  cc_expiry: createStripeValue(),
  cc_number: createStripeValue(),
  [RETURN_DATETIME_FIELD_NAME]: moment(),
  // TODO Unify COUPON_CODE_FIELD_NAME and COUPON_STATE
  [COUPON_CODE_FIELD_NAME]: '',
  [DEPARTING_PICKUP_FIELD_NAME]: moment(),
  [RETURNING_PICKUP_FIELD_NAME]: moment(),
  [RIDE_DURATION_FIELD_NAME]: 0,
  [COUPON_STATUS]: {
    wrongCode: false,
    disabled: false,
    showRefresh: false,
    showHint: false,
    savedValue: '',
  },
  [TRAVELER_FIELD_NAME]: false,
  [PHONE_CODE_FIELD_NAME]: null,
  [UPGRADES_FIELD_NAME]: [],
  [PRICE_FIELD_NAME]: {},
  [BOOKING_FEE_FIELD_NAME]: '',
  [GRATUITY_FIELD_NAME]: '0',
  [MEET_AND_GREET_BOX_CLOSED]: false,
  [BOOKING_FEE_STATUS]: {
    updating: false,
    savedValue: '',
    showHint: false,
  },
  [GRATUITY_STATUS]: {
    updating: false,
    savedValue: 0,
    showHint: false,
  },
  [ACCOUNT_ID_STATUS]: {
    updating: false,
    savedValue: '',
  },
  [ACCOUNT_ID_FIELD_NAME]: '',
  [SAVE_TA_PROFILE_FIELD_NAME]: false,
  [SAVE_TA_CC_FIELD_NAME]: false,
  [DEPARTING_FLIGHT_FIELD_NAME]: null,
  [RETURNING_FLIGHT_FIELD_NAME]: null,
};

const ciUserDataValidator = defineTypes({
  [FIRST_NAME_FIELD_NAME]: { isRequired: true, type: 'text' },
  [LAST_NAME_FIELD_NAME]: { isRequired: true, type: 'text' },
  [EMAIL_FIELD_NAME]: { isRequired: true, type: 'email' },
  [EMAIL_CONFIRMATION_FIELD_NAME]: { isRequired: true, type: 'email' },
  [PHONE_CODE_FIELD_NAME]: {
    isRequired: true,
    type: 'phone_code',
    requiredError: 'VALIDATION.PHONE_CODE_REQUIRED',
  },
  [PHONE_NUMBER_FIELD_NAME]: {
    isRequired: true,
    type: 'text',
    requiredError: 'VALIDATION.PHONE_NUMBER_REQUIRED',
  },
  [PASSWORD_FIELD_NAME]: { isRequired: false, type: 'password' },
});

const taUserValidator = defineTypes({
  [TA_CONFIRMATION_EMAIL_FIELD_NAME]: { isRequired: false, type: 'email' },
});

const flightInformationValidator = defineTypes({
  [DEPARTING_FLIGHT_FIELD_NAME]: {
    isRequired: true,
    requiredError: 'VALIDATION.FLIGHT_REQUIRED',
  },
});

const returnFlightInformationValidator = defineTypes({
  [RETURNING_FLIGHT_FIELD_NAME]: {
    isRequired: true,
    requiredError: 'VALIDATION.FLIGHT_REQUIRED',
  },
});

const membershipNumberValidator = defineTypes({
  [LOYALTY_MEMBER_NUMBER_FIELD_NAME]: {
    isRequired: !!config.LOYALTY_PROGRAM_NUMBER_REQUIRED,
    type: 'text',
  },
});

const validatePaxValues = (
  values,
  errorList,
  passengersCount,
  extraPaxRequired
) => {
  const paxConfig = Array(passengersCount - 1)
    .fill()
    .reduce((acc, _, index) => {
      if (extraPaxRequired) {
        return {
          ...acc,
          [`${TRAVELER_FIRST_NAME_FIELD_NAME}-${index}`]: {
            isRequired: extraPaxRequired,
            type: 'text',
          },
          [`${TRAVELER_LAST_NAME_FIELD_NAME}-${index}`]: {
            isRequired: extraPaxRequired,
            type: 'text',
          },
          [`${TRAVELER_EMAIL_FIELD_NAME}-${index}`]: {
            isRequired: extraPaxRequired,
            type: 'email',
          },
        };
      } else if (values[`${TRAVELER_EMAIL_FIELD_NAME}-${index}`]) {
        return {
          ...acc,
          [`${TRAVELER_EMAIL_FIELD_NAME}-${index}`]: {
            isRequired: false,
            type: 'email',
          },
        };
      }
      return acc;
    }, {});
  const paxValidator = defineTypes(paxConfig);

  errorList.push(paxValidator(values));
};

const validateTrainField = (fieldName) => {
  return defineTypes({
    [fieldName]: {
      validate: (value) => {
        if (!value?.company || !value?.train) {
          return 'VALIDATION.TRAIN_INFO_REQUIRED';
        }
      },
    },
  });
};

const validateCruiseShipNameField = defineTypes({
  [DEPARTING_CRUISE_FIELD_NAME]: {
    isRequired: !!config.REQUIRED_CRUISE_SHIP_NAME,
    type: 'text',
  },
  [DEPARTING_SHIP_FIELD_NAME]: {
    isRequired: !!config.REQUIRED_CRUISE_SHIP_NAME,
    type: 'text',
  },
});

const validate = (values, props) => {
  const { behavesAsTA } = props;

  const departingTrip = preprocessTripObject(values[DEPARTING_TRIP_FIELD_NAME]);
  const returningTrip = preprocessTripObject(values[RETURN_TRIP_FIELD_NAME]);
  let ignoreFlightInfo = false;
  let passengersCount = 1;
  let extraPaxRequired = false;
  if (departingTrip) {
    ignoreFlightInfo = departingTrip.details.ignoreFlightInfo;
    passengersCount = departingTrip.details.passengersCount;
    extraPaxRequired = departingTrip.details.extraPaxRequired;
  }

  const hasReturnTrip =
    values[RETURN_BOOK_STATE_FIELD_NAME] === RETURN_TRIP_BOOKED;

  const errorList = [];
  errorList.push(ciUserDataValidator(values));

  if (behavesAsTA) {
    errorList.push(taUserValidator);
  }

  if (!ignoreFlightInfo) {
    errorList.push(flightInformationValidator(values));
  }

  if (hasReturnTrip && !ignoreFlightInfo) {
    errorList.push(returnFlightInformationValidator(values));
  }

  if (values[EMAIL_FIELD_NAME] !== values[EMAIL_CONFIRMATION_FIELD_NAME]) {
    errorList.push({
      [EMAIL_CONFIRMATION_FIELD_NAME]: NOT_SAME_EMAIL_ERROR_TYPE,
    });
  }

  // check extra passenger data
  validatePaxValues(values, errorList, passengersCount, extraPaxRequired);

  // Validate train fields if needed
  if (config.REQUIRED_TRAIN_FIELD && config.SHOW_TRAIN_SELECTION) {
    if (departingTrip?.routing.trainStationName) {
      errorList.push(validateTrainField(TRAIN_INFO_FIELD_NAME)(values));
    }
    if (returningTrip?.routing.trainStationName) {
      errorList.push(validateTrainField(RETURN_TRAIN_INFO_FIELD_NAME)(values));
    }
  }

  // Validate ship name fields if needed
  if (config.REQUIRED_CRUISE_SHIP_NAME) {
    errorList.push(validateCruiseShipNameField(values));
  }

  // Validate Membership Number field if necessary
  if (config.LOYALTY_PROGRAM_ENABLED) {
    errorList.push(membershipNumberValidator(values));
  }

  if (
    config.SHOW_AGREE_WITH_TERMS_CHECKBOX &&
    !values[AGREE_WITH_TERMS_FIELD_NAME]
  ) {
    errorList.push({
      [AGREE_WITH_TERMS_FIELD_NAME]: MUST_AGREE_TNC_ERROR_TYPE,
    });
  }

  return Object.assign({}, ...errorList);
};

export const formConfig = {
  form: BOOK_TRIP_FORM,
  destroyOnUnmount: false,
  touchOnBlur: true,
  touchOnChange: true,
  initialValues,
  validate,
  onSubmitFail: () => {
    setTimeout(scrollToFirstInvalidField);
  },
};

export const mapStateToProps = (state) => {
  const {
    bookTrip: { loading, errorCode, errorMessage, errorPopup, errorProps },
    creditcards,
  } = state;

  const cardsList = getTACustomerProfileCCList(state);
  const user = getUser(state);
  const behavesAsTA = behavesAsTravelAgent(state);

  return {
    loading,
    errorCode,
    errorMessage,
    errorPopup,
    errorProps,
    user,
    behavesAsTA,
    creditcards: { ...creditcards, list: cardsList },
  };
};

const mapDispatchToProps = {
  loadBookTrip,
  confirmBooking,
  getCustomerProfileList,
};

export const builder = (ComposedComponent) => {
  class BookTrip extends Component {
    componentDidMount() {
      const { loadBookTrip } = this.props;
      loadBookTrip();
    }

    componentWillReceiveProps(nextProps) {
      const { loading, errorPopup, errorCode, errorMessage, errorProps } =
        nextProps;
      if (
        errorPopup &&
        (!this.props.errorPopup || errorCode !== this.props.errorCode)
      ) {
        this.props.subpages.pushPage(ErrorLayout, {
          errorCode,
          errorMessage,
          errorPopup,
          errorProps,
        });
      }
      if (loading && !this.props.loading)
        this.props.subpages.pushPage(LoadingBox);
      if (!loading && this.props.loading) this.props.subpages.popPage();
    }

    handleSubmit = (createPayment) => {
      return this.props.handleSubmit(() =>
        this.props.confirmBooking({ createPayment })
      )();
    };

    render() {
      // key="trip" added to tell react-router not to unmount component related to route
      // because it was a cause of loosing scroll position
      return (
        <ComposedComponent
          key="trip"
          seachResultsLink={this.searchResultsLocation}
          {...this.props}
          onSubmit={this.handleSubmit}
        />
      );
    }
  }

  // TODO: move to Flow types
  // BookTrip.propTypes = {
  //   ...propTypes,
  //   loadBookTrip: PropTypes.func.isRequired,
  //   subpages: PropTypes.object.isRequired,
  //   errorPopup: PropTypes.bool,
  //   errorMessage: PropTypes.string,
  //   errorCode: PropTypes.number,
  //   errorProps: PropTypes.object,
  //   freeCancellation: PropTypes.bool,
  //   loading: PropTypes.bool.isRequired,
  //   mobile: PropTypes.bool.isRequired,
  //   behavesAsTA: PropTypes.bool
  // };

  const redirectConfig = {
    shouldRedirect: ({ user, isLoading }) =>
      !user && !isLoading && config.ALLOW_ONLY_REGISTERED,
    redirectTo: '/user/login',
  };

  return compose(
    allowOrRedirect(redirectConfig),
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig),
    connectSubpages()
  )(BookTrip);
};

export default builder;
