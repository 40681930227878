import { getMainStep } from 'app/utils/trip';
import {
  BOOK_TRIP_FORM,
  BOOKING_FEE_STATUS,
  DEPARTING_TRIP_FIELD_NAME,
  SESSION_ID_FIELD_NAME,
  RETURN_BOOK_STATE_FIELD_NAME,
  RETURN_TRIP_BOOKED,
  TRIP_EXPIRED_ERROR_CODE,
  UPGRADE_IDS,
  CONFIRM_BOOKING,
  ON_DEMAND_CATEGORY,
  DEPARTING_FLIGHT_FIELD_NAME,
  RETURNING_FLIGHT_FIELD_NAME,
} from 'app/constants';

import { call, put, select, race, take } from 'redux-saga/effects';
import { showErrorNotification } from 'app/sagas/utils';
import { LOCATION_CHANGE } from 'app/history';
import { delay } from 'redux-saga';
import {
  doBookTrip,
  setTripError,
  clearTripError,
  toggleOptionalAmenity,
  addCoupon,
} from 'app/actions/book-trip';
import getPrice from 'app/sagas/watchBookTrip/watchUpdatePrice/getPrice';
import {
  getBookingFeeFieldValue,
  getBookingFeeStatus,
  getCouponFieldValue,
} from 'app/sagas/selectors';
import doSetupUpgrades from '../doSetupUpgrades';
import { adjustPickupTimeByFlight } from '../watchFlightInformation/adjustPickupTimeByFlight';

// import { APIError } from 'mz-sdk';
import resolveFormValues from './resolveFormValues';
import processResearchTrip from './processResearchTrip';
import { initialize, change } from 'redux-form';
import { reportSoldOut, reportTripSelected } from 'app/sagas/logging/google';

function* ensurePickupTimeForFlightIsValid(resolvedValues, trip) {
  const isRoundtrip =
    resolvedValues[RETURN_BOOK_STATE_FIELD_NAME] === RETURN_TRIP_BOOKED;
  const flightField =
    trip.startLocation?.type === 'airport'
      ? DEPARTING_FLIGHT_FIELD_NAME
      : isRoundtrip && trip.endLocation?.type === 'airport'
      ? RETURNING_FLIGHT_FIELD_NAME
      : null;

  if (!flightField || !resolvedValues[flightField]) {
    return;
  }

  yield call(adjustPickupTimeByFlight, {
    meta: { field: flightField },
    payload: resolvedValues[flightField],
  });
}

export default function* loadBookTrip(action) {
  try {
    yield put(clearTripError());

    const { payload: { reloadTrip, updatedTrip, forceMeetAndGreet } = {} } =
      action;

    const values = yield call(resolveFormValues, { reloadTrip, updatedTrip });
    const trip = values[DEPARTING_TRIP_FIELD_NAME];
    yield put(initialize(BOOK_TRIP_FORM, values));

    // Update booking fee saved value based on user default
    const bookingFee = yield select(getBookingFeeFieldValue);
    const bookingFeeStatus = yield select(getBookingFeeStatus);
    yield put(
      change(BOOK_TRIP_FORM, BOOKING_FEE_STATUS, {
        ...bookingFeeStatus,
        savedValue: bookingFee,
      })
    );
    const couponValue = yield select(getCouponFieldValue);

    try {
      const priceParams = !updatedTrip ? { optional_amenities: undefined } : {};
      yield call(getPrice, { priceParams });
      // add coupon only after price initiaized.
      // it's a safe way - first init BTP and payment section and then apply coupon
      if (couponValue && !reloadTrip) {
        yield put(addCoupon());
      }
    } catch (error) {
      yield call(showErrorNotification, { error });
    }

    yield call(doSetupUpgrades, BOOK_TRIP_FORM, reloadTrip);

    const { from, to, provider } = getMainStep(trip);
    yield put(
      doBookTrip({
        trip_id: trip.id,
        session_id: values[SESSION_ID_FIELD_NAME],
        provider: provider.name,
        from: from.location.iataCode || from.location.fullAddress,
        mode:
          values[RETURN_BOOK_STATE_FIELD_NAME] === RETURN_TRIP_BOOKED
            ? 'roundtrip'
            : 'oneway',
        to: to.location && (to.location.iataCode || to.location.fullAddress),
        ondemand: trip.type === ON_DEMAND_CATEGORY,
      })
    );
    yield call(reportTripSelected);

    if (forceMeetAndGreet) {
      yield put(toggleOptionalAmenity(UPGRADE_IDS.MEET_AND_GREET));
    }

    // Make sure pickup time of the trip corresponds to a resolved flight
    yield call(ensurePickupTimeForFlightIsValid, values, trip);

    // wait when trip expired and do research, or cancel it if URL changed or
    // resrvation create is running
    const expirationPeriod = trip.expiresIn * 1000;

    const { expired } = yield race({
      expired: call(delay, expirationPeriod),
      confirmBooking: take(CONFIRM_BOOKING),
      exit: take(LOCATION_CHANGE),
    });

    if (expired) {
      yield call(processResearchTrip, trip);
      yield call(reportSoldOut);
    }
  } catch (error) {
    yield call(showErrorNotification, { error });
    yield put(
      setTripError({
        errorCode: TRIP_EXPIRED_ERROR_CODE,
        errorMessage: error.message,
        errorPopup: false,
        errorProps: { searchParams: {} },
      })
    );
  }
}
