import React from 'react';
import config from 'config';
import { isMobileBrowser } from 'mz-ui-kit/utils';
import { hook } from 'app/utils/hook';
import { USER_ROUTES_HOOK } from 'app/constants';
import importWithRetry from 'mz-utils/importWithRetry';

// Constants
export const USER_TOOL = 'user';

// Lazy loaded routes
const LoginPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/LoginPage'))
);
const GuestUserPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/GuestUserPage'))
);
const ForgotPasswordPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/ForgotPasswordPage'))
);
const ResetPasswordPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/ResetPasswordPage'))
);
const RegisterPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/RegisterPage'))
);
const ReactivateAccountPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/ReactivateAccountPage'))
);
const DashboardPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/DashboardPage'))
);
const UserCreditCardsPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/UserCreditCardsPage'))
);
const TACustomerProfilesPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/TACustomerProfilesPage'))
);
const UserInformationPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/UserInformationPage'))
);
const UserFavouriteLocationsPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/UserFavouriteLocationsPage'))
);
const UserCheckYourEmailPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/UserCheckYourEmailPage'))
);

const UserAccountPage = React.lazy(() =>
  isMobileBrowser()
    ? importWithRetry(() => import('app/pages/UserAccountPage/Mobile'))
    : importWithRetry(() => import('app/pages/UserAccountPage/Desktop'))
);

const routes = [
  {
    path: `/${USER_TOOL}/login`,
    component: LoginPage,
    wrapper: GuestUserPage,
  },
  !config.PASSWORD_RESET_ABILITY
    ? null
    : {
        path: `/${USER_TOOL}/forgot-password`,
        component: ForgotPasswordPage,
        wrapper: GuestUserPage,
      },
  {
    path: `/reset-password/confirm/:token`,
    component: ResetPasswordPage,
    wrapper: GuestUserPage,
  },
  {
    path: `/${USER_TOOL}/register`,
    component: RegisterPage,
    wrapper: GuestUserPage,
  },
  {
    path: `/${USER_TOOL}/reactivate`,
    component: ReactivateAccountPage,
    wrapper: GuestUserPage,
  },
  {
    path: `/${USER_TOOL}/check-your-email`,
    component: UserCheckYourEmailPage,
    wrapper: GuestUserPage,
  },
  {
    path: `/${USER_TOOL}/account`,
    component: UserAccountPage,
    wrapper: DashboardPage,
  },
  {
    path: `/${USER_TOOL}/info`,
    component: UserInformationPage,
    wrapper: DashboardPage,
  },
  {
    path: `/${USER_TOOL}/credit-cards`,
    component: UserCreditCardsPage,
    wrapper: DashboardPage,
  },
  {
    path: `/${USER_TOOL}/favorite-locations`,
    component: UserFavouriteLocationsPage,
    wrapper: DashboardPage,
  },
  {
    path: `/${USER_TOOL}/customer-profiles`,
    component: TACustomerProfilesPage,
    wrapper: DashboardPage,
  },
].filter(Boolean);

export default () =>
  hook({
    name: USER_ROUTES_HOOK,
    args: [routes],
    defaultHandler: () => routes,
  });
