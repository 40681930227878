import config from 'config';
import { registerIntegration, CATEGORIES } from 'mz-ui-kit/core/CookieNotice';

/**
 * Inject tag in the head tag
 */
const injectToHead = (tag, attrs) => {
  const newTag = document.createElement(tag);
  Object.keys(attrs).forEach((k) => {
    newTag[k] = attrs[k];
  });
  document.getElementsByTagName('head')[0].appendChild(newTag);
};

/**
 * Google Tag Manager
 */
const initGTM = () => {
  if (!config.TAG_MANAGER_KEY) return;

  // Async initialization of GTM
  window.dataLayer = window.dataLayer || [];

  registerIntegration({
    id: 'google-tag-manager',
    category: CATEGORIES.ANALYTICS,
    onEnabled: () => {
      /* eslint-disable */
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = '//www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', config.TAG_MANAGER_KEY);
      /* eslint-enable */
    },
  });
};

/**
 * A little function that cache  `gid` parameter in LS and reuse it
 * if no gid provided in the URL
 * @type {String}
 */
const CUSTOM_USER_ID_KEY = 'ga-gid';
const getCustomUserId = () => {
  const gidMatch = window.location.search.match(/(?:\?|&)gid=([^&]+|$)/i);
  if (gidMatch) {
    localStorage.setItem(CUSTOM_USER_ID_KEY, gidMatch[1]);
    return gidMatch[1];
  }
  return localStorage.getItem(CUSTOM_USER_ID_KEY);
};

/**
 * Google Analytics
 */
/* eslint-disable */
const initAnalytics = () => {
  // Async GA initialization
  window.ga =
    window.ga ||
    function () {
      (ga.q = ga.q || []).push(arguments);
    };
  ga.l = +new Date();

  // Load google analytics
  registerIntegration({
    id: 'google-analytics-v2',
    category: CATEGORIES.ANALYTICS,
    onEnabled: () => {
      injectToHead('script', {
        async: true,
        src: 'https://www.google-analytics.com/analytics.js',
      });
    },
  });

  // Add all trackers
  const trackingKeys = [
    { name: '', id: config.GOOGLE_ANALYTICS_KEY },
    { name: 'partner', id: config.GOOGLE_ANALYTICS_KEY_PARTNER },
    ...config.GOOGLE_ANALYTICS_ADDITIONAL_KEYS.map((k) => {
      const idName = `${k[0] || k[1] || Math.random()}`.replace(
        /[^a-zA-Z]/g,
        ''
      );
      return { name: idName, id: k[1] };
    }),
  ].filter((x) => x.id);

  // Create all trackers
  const gid = getCustomUserId();
  trackingKeys.forEach((x) => {
    const cmdPrefix = x.name ? `${x.name}.` : '';
    ga('create', x.id, 'auto', x.name);
    if (gid) {
      ga(`${cmdPrefix}set`, 'userId', gid);
    }

    if (config.GOOGLE_ANALYTICS_LINKER_DOMAINS.length) {
      ga(`${cmdPrefix}require`, 'linker');
      ga(
        `${cmdPrefix}linker:autoLink`,
        config.GOOGLE_ANALYTICS_LINKER_DOMAINS.map((p) => new RegExp(p[0], 'i'))
      );
    }
  });

  // Set all trackers created
  window.gaTrackers = trackingKeys.map((x) => (x.name ? `${x.name}.` : ''));

  // Track analytics loading and resolve promise when loaded
  ga((tracker) => {
    const cid = tracker && tracker.get('clientId');
    window.gaClientId = cid;
  });
};
/* eslint-enable */

/**
 * Zendesk initialization
 */

const initZendesk = (locale) => {
  if (config.ZENDESK_ENABLED) {
    window.zESettings = {
      webWidget: {
        chat: {
          tags: ['website', `partner-${config.PARTNER_REF || 'mozio'}`],
        },
        color: {
          theme: config[config.HELP_CHAT_THEME_COLOR],
          button: config[config.HELP_BUTTON_COLOR],
        },
        helpCenter: {
          originalArticleButton: false,
        },
      },
    };

    // SNIPPET MODIFIED: Integrate only when the user consent
    if (!window.zEmbed) {
      const zEQueue = [];
      window.zEmbed = (...args) => zEQueue.push(args);
      window.zE = window.zE || window.zEmbed;

      registerIntegration({
        id: 'zendesk',
        category: CATEGORIES.FUNCTIONALITY,
        onEnabled: () => {
          /* eslint-disable */
          let n,
            o,
            d,
            i,
            s,
            r = document.createElement('iframe');
          (r.src = 'javascript:false'),
            (r.title = ''),
            (r.role = 'presentation'),
            ((r.frameElement || r).style.cssText = 'display: none'),
            (d = document.getElementsByTagName('script')),
            (d = d[d.length - 1]),
            d.parentNode.insertBefore(r, d),
            (i = r.contentWindow),
            (s = i.document);
          try {
            o = s;
          } catch (e) {
            (n = document.domain),
              (r.src =
                'javascript:var d=document.open();d.domain="' +
                n +
                '";void(0);'),
              (o = s);
          }
          (o.open()._l = function () {
            var e = this.createElement('script');
            n && (this.domain = n),
              (e.id = 'js-iframe-async'),
              (e.src =
                'https://assets.zendesk.com/embeddable_framework/main.js'),
              (this.t = +new Date()),
              (this.zendeskHost = config.ZENDESK_HOST),
              (this.zEQueue = zEQueue),
              this.body.appendChild(e);
          }),
            o.write('<body onload="document._l();">'),
            o.close();
          /* eslint-enable */
        },
      });
    }

    window.zE('webWidget', 'setLocale', locale);
  }
};

/**
 * Fullstory integration
 */
const initFullstory = () => {
  if (
    config.FULLSTORY_ENABLED &&
    config.FULLSTORY_PROJECT_ID &&
    config.HERTZ_APP
  ) {
    window['_fs_debug'] = false;
    window['_fs_host'] = 'fullstory.com';
    window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
    window['_fs_org'] = config.FULLSTORY_PROJECT_ID;
    window['_fs_namespace'] = 'FS';

    /* eslint-disable */
    // SNIPPET MODIFIED: Integrate only when the user consent
    (function (m, n, e, t, l, o, g, y) {
      registerIntegration({
        category: CATEGORIES.ANALYTICS,
        onEnabled: () => {
          if (e in m) {
            if (m.console && m.console.log) {
              m.console.log(
                'FullStory namespace conflict. Please set window["_fs_namespace"].'
              );
            }
            return;
          }
          g = m[e] = function (a, b, s) {
            g.q ? g.q.push([a, b, s]) : g._api(a, b, s);
          };
          g.q = [];
          o = n.createElement(t);
          o.async = 1;
          o.crossOrigin = 'anonymous';
          o.src = 'https://' + _fs_script;
          y = n.getElementsByTagName(t)[0];
          y.parentNode.insertBefore(o, y);
          g.identify = function (i, v, s) {
            g(l, { uid: i }, s);
            if (v) g(l, v, s);
          };
          g.setUserVars = function (v, s) {
            g(l, v, s);
          };
          g.event = function (i, v, s) {
            g('event', { n: i, p: v }, s);
          };
          g.anonymize = function () {
            g.identify(!!0);
          };
          g.shutdown = function () {
            g('rec', !1);
          };
          g.restart = function () {
            g('rec', !0);
          };
          g.log = function (a, b) {
            g('log', [a, b]);
          };
          g.consent = function (a) {
            g('consent', !arguments.length || a);
          };
          g.identifyAccount = function (i, v) {
            o = 'account';
            v = v || {};
            v.acctId = i;
            g(o, v);
          };
          g.clearUserCookie = function () {};
          g.setVars = function (n, p) {
            g('setVars', [n, p]);
          };
          g._w = {};
          y = 'XMLHttpRequest';
          g._w[y] = m[y];
          y = 'fetch';
          g._w[y] = m[y];
          if (m[y])
            m[y] = function () {
              return g._w[y].apply(this, arguments);
            };
          g._v = '1.3.0';
        },
      });
    })(window, document, window['_fs_namespace'], 'script', 'user');
    /* eslint-enable */
  }
};

/**
 * Initialize loggly
 */
const initLoggly = () => {
  if (config.LOGGLY_KEY) {
    window._LTracker = window._LTracker || [];
    window._LTracker.push({
      logglyKey: config.LOGGLY_KEY,
      sendConsoleErrors: true,
    });

    registerIntegration({
      id: 'loggly',
      category: CATEGORIES.ANALYTICS,
      onEnabled: () => {
        injectToHead('script', {
          async: true,
          src: 'https://cloudfront.loggly.com/js/loggly.tracker-latest.min.js',
        });
      },
    });
  }
};

/**
 * Stripe API initialization
 */
export const initStripe = () => {
  if (typeof window.Stripe !== 'undefined') {
    return Promise.resolve();
  }
  return new Promise((r) => {
    injectToHead('script', {
      async: true,
      src: 'https://js.stripe.com/v3/',
      onload: r,
    });
  });
};

const initAirFranceTracking = () => {
  if (config.PARTNER_REF !== 'airfrance') return;

  window.dataLayer = window.dataLayer || [];
  window.gtag = window.gtag || ((...args) => window.dataLayer.push(args));

  window.gtag('js', new Date());
  window.gtag('config', 'AW-937753808');
  window.gtag('config', 'DC-5109792');

  // Load google analytics
  registerIntegration({
    id: 'google-analytics-airfrance',
    category: CATEGORIES.ANALYTICS,
    onEnabled: () => {
      injectToHead('script', {
        async: true,
        src: 'https://www.googletagmanager.com/gtag/js?id=AW-937753808',
      });
    },
  });
};

const initGoogleOptimize = () => {
  if (!config.EXPERIMENTS_ENABLED) return;

  injectToHead('script', {
    async: true,
    src: 'https://www.googleoptimize.com/optimize.js?id=OPT-TVG2XZW',
  });
};

const initDelighted = () => {
  !(function (e, t, r, n) {
    if (!e[n]) {
      for (
        var a = (e[n] = []),
          i = [
            'survey',
            'reset',
            'config',
            'init',
            'set',
            'get',
            'event',
            'identify',
            'track',
            'page',
            'screen',
            'group',
            'alias',
          ],
          s = 0;
        s < i.length;
        s++
      ) {
        var c = i[s];
        a[c] =
          a[c] ||
          (function (e) {
            return function () {
              var t = Array.prototype.slice.call(arguments);
              a.push([e, t]);
            };
          })(c);
      }
      a.SNIPPET_VERSION = '1.0.1';
      var o = t.createElement('script');
      (o.type = 'text/javascript'),
        (o.async = !0),
        (o.src =
          'https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/' +
          r +
          '/' +
          n +
          '.js');
      var l = t.getElementsByTagName('script')[0];
      l.parentNode.insertBefore(o, l);
    }
  })(window, document, 'TMqhTOBfNZLJ9lFI', 'delighted');
};

// Init analytics only
export const initTrackers = () => {
  if (config.ENV !== 'dev') {
    initLoggly();
    initAnalytics();
    initGTM();
    initAirFranceTracking();
    initGoogleOptimize();
    initDelighted();
  }
};

// All together
export const initOthers = (locale) => {
  if (config.ENV !== 'dev') {
    initZendesk(locale);
    initFullstory();
  }
};
