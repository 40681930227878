import { call, put } from 'redux-saga/effects';
import { getHistory } from 'app/history';
import { auth } from 'mz-sdk';
import { showNotification } from 'app/actions/notifications';
import { NOTIFICATION_ERROR } from 'app/constants';

export default function* doLogout() {
  try {
    yield call(auth.logout);

    // Delete user data for third-party libs.
    window.mozioUser = null;

    // Redirect to the home page on logout
    const history = yield call(getHistory);
    yield call(history.push, '/');
  } catch (error) {
    yield put(
      showNotification({
        type: NOTIFICATION_ERROR,
        message: error.message,
      })
    );
  }
}
